<template>
  <BaseListPage locale-section="pages.crossForms" route="crossform">
    <CrossFormsList />
  </BaseListPage>
</template>

<script>
export default {
  name: "CrossFormsPage",
  components: {
    BaseListPage: () => import("@/components/BaseListPage"),
    CrossFormsList: () => import("./CrossFormsList")
  }
};
</script>
